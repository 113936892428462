import * as React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";
import palmomediaLogo from "../images/palmomedia-logo-2021.png";
import xing from "../images/icon_xing.png";
import gitlab from "../images/icon_gitlab.png";
import twitter from "../images/icon_twitter.png";
import x from "../../static/twitter-x-logo.png";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  header = (
    <Link to="/">
      <img
        src={palmomediaLogo}
        title={title}
        alt={title}
        height="80"
        width="193"
        className="logo"
      />
    </Link>
  );

  return (
    <>
      <div className="global-wrapper" style={{ minHeight: "78%" }}>
        <header className="global-header">
          <div className="row" style={{ marginLeft: 30, marginRight: 30 }}>
            <div className="col-sm-6">
              {header}
              <br />
            </div>
            <div className="col" style={{ padding: 10, paddingTop: 40 }}>
              <Link
                to="/ueber-uns"
                className={
                  location.pathname.indexOf("ueber-uns") != -1
                    ? "active"
                    : "headerlink"
                }
              >
                Über uns
              </Link>
            </div>
            <div className="col" style={{ padding: 10, paddingTop: 40 }}>
              <Link
                to="/kontakt"
                className={
                  location.pathname.indexOf("kontakt") != -1
                    ? "active"
                    : "headerlink"
                }
              >
                Kontakt
              </Link>
            </div>
            <div className="col" style={{ padding: 10, paddingTop: 40 }}>
              <Link
                to="/links"
                className={
                  location.pathname.indexOf("links") != -1
                    ? "active"
                    : "headerlink"
                }
              >
                Links
              </Link>
            </div>
          </div>
        </header>
        <main>{children}</main>
      </div>
      <footer>
        <div
          style={{ padding: 20, marginTop: -10, marginBottom: -10 }}
          className="global-wrapper text-center text-sm-start"
        >
          <div className="row">
            <div className="col-sm paddingIfSmall">
              <strong>Unternehmen</strong>
              <br />
              <Link to="/impressum">Impressum</Link>
              <br />
              <Link to="/kontakt">Kontakt</Link>
              <br />
              <Link to="/datenschutzerklaerung">Datenschutz</Link>
              <br />
              <Link to="/ueber-uns">Über uns</Link>
              <br />
              <br />
              <strong>Leistungen</strong>
              <br />
              <Link to="/web-und-app-entwicklung">App Entwicklung</Link>
              <br />
              <Link to="/webseiten-gestaltung-und-programmierung">
                Webseiten Gestaltung
              </Link>
              <br />
              <Link to="/schnelle-webseiten">Webseiten Performance</Link>
              <br />
              <Link to="/seo-und-devops">SEO &amp; DevOps</Link>
            </div>
            <div className="col-sm paddingIfSmall">
              <p>
                <a
                  href="https://x.com/mitchobrian"
                  rel="noreferrer"
                  target="_blank"
                  title="x ehemals twitter"
                >
                  <img
                    src={x}
                    height="30"
                    width="30"
                    border="0"
                    alt="x ehemals twitter"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://www.xing.com/profile/Michael_Palmer5/cv"
                  rel="noreferrer"
                  target="_blank"
                  title="xing"
                >
                  <img
                    src={xing}
                    height="30"
                    width="30"
                    border="0"
                    alt="xing"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://gitlab.com/mitchobrian"
                  rel="noreferrer"
                  target="_blank"
                  title="gitlab"
                >
                  <img
                    src={gitlab}
                    height="30"
                    width="30"
                    border="0"
                    alt="gitlab"
                  />
                </a>
              </p>
            </div>
            <div className="col-sm-6 text-center text-sm-end">
              <strong>Die Seite wurde...</strong>
              <br />
              ...erstellt mit{" "}
              <a href="https://www.gatsbyjs.com" rel="noreferrer">
                Gatsby
              </a>
              <br />
              Grafiken bearbeitet mit{" "}
              <a href="https://www.photopea.com" rel="noreferrer">
                Photopea
              </a>
              <br />
              Code verwaltet bei{" "}
              <a href="https://gitlab.com/palmomedia" rel="noreferrer">
                GitLab
              </a>
              <br />
              <br />
              <strong>Weiteres</strong>
              <br />
              <Link to="/links">Projekte</Link>
              <br />
              <Link to="https://mitchobrian.medium.com/" rel="noreferrer">
                Blog
              </Link>
              <br />
              <Link to="/apps">Apps</Link>
              <br />
              <br />
            </div>
            <div className="col-12 text-center text-sm-end">
              © {new Date().getFullYear()} Copyright by <b>palmomedia</b> |{" "}
              <a
                href="https://michaelpalmer.de/"
                target="_blank"
                rel="noreferrer"
              >
                Michael Palmer
              </a>
            </div>
          </div>
        </div>
      </footer>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="Okay"
        style={{
          position: "fixed",
          color: "#fff",
          opacity: 0.9,
          fontSize: 16,
          background: "#5095a1",
          padding: 40,
          margin: 0,
          bottom: 0,
        }}
        buttonClasses="btn btn-light"
        containerClasses="col-12 text-center"
      >
        Diese Webseite verwendet <b>Cookies</b> zur Nutzung von Google
        Analytics.
        <br />
        <a
          href="/datenschutzerklaerung"
          title="Datenschutzerklärung"
          className="btn"
        >
          Datenschutzerklärung
        </a>
        <br />
        <br />
      </CookieConsent>
    </>
  );
};

export default Layout;
